import Alpine from "alpinejs";

Alpine.magic("storage", () => {
    return {
        set(key, value) {
            return window.localStorage.setItem(key, value);
        },
        get(key) {
            return window.localStorage.getItem(key);
        }
    };
});