import Alpine from "alpinejs";
import siteConfig from "../../modules/siteConfig";

Alpine.magic("fetch", () => {
    return {
        async get(url, params={}) {
            return await fetch(url, {
                headers: {
                    Accept: "application/json"
                },
                ...params
            })
        },
        async post(url, params={}) {
            return await fetch(url, {
                headers: {
                    "Content-Type": "application/json",
                    "x-sny-siteoverride": siteConfig.site,
                },
                method: "POST",
                ...params,
            })
        }
    };
});